//Page
(function ($, window, document, undefined) {

    "use strict";


    var pluginName = "pages",
        defaults = {
            home: {
                elementsToSubtract  : [ $('.menu'), $('.header')],
                minHeight : 200
            }

        };



    function Pages(element, options) {
        this.element = element;
        this.$element = $(element);

        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    $.extend(Pages.prototype, {
        init: function () {
            var self = this;
            if(this.$element.is('.page-home')){
                $(window).resize(function(){
                    self.positionKoekenVrouw();
                });
                $('.koekenvrouw').imagesLoaded(function(){
                    self.positionKoekenVrouw();
                });
            }/*else if(this.$element.is('.page-paintings, .page-characters, .page-collages')){
            }*/

        },
        positionKoekenVrouw: function(){
            var $container = $('.koekenvrouw');
            var $img = $('.koekenvrouw img');
            var height = $(window).height();
            $.each(this.settings.home.elementsToSubtract, function (i, el) {
                height -= el.height();
            });
            height -= 50;
            $container.css('height', height);
            $img.css('margin-left', - $img.width()/2);
            if($img.height() < this.settings.home.minHeight ){
                $container.toggleClass('hidden', true);
            }else{
                $container.toggleClass('hidden', false);
            }


        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Pages(this, options));
            }
        });
    };

})(jQuery, window, document);
