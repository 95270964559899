//Page
(function ($, window, document, PhotoSwipe, PhotoSwipeUI_Default, undefined) {

    "use strict";


    var pluginName = "gallery",
        defaults = {
            $overview: $('.gallery-overview'),
            $thumbs: $('.gallery-thumb'),
            $thumbsClick: $('.gallery-thumb a'),
            $big: $('.gallery-big'),
            $bigClick: $('.gallery-big a.big'),
            $prev: $('.gallery-prev'),
            $next: $('.gallery-next'),
            $pswp: $('.pswp')
        };


    function Gallery(element, options) {
        this.element = element;
        this.$element = $(element);

        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    $.extend(Gallery.prototype, {
        init: function () {
            var self = this;

            $(document).ready(function () {
                self.initEvents();
                self.initPhotoswipe();


            });
            this.currentIndex = 0;
            this.photoswipeItems = [];

        },

        initEvents: function () {
            var self = this;

            self.settings.$thumbsClick.click(function (e) {
                e.preventDefault();
                //var $this = $(this);


            });

            self.settings.$thumbs.click(function (e) {
                e.preventDefault();

                var index = $(this).index() - 1;
                self.openPhotoswipe( index , true, true );


            });


            self.settings.$overview.imagesLoaded(function () {
                self.settings.$thumbs.removeClass('hidden');

                self.settings.$overview.packery({
                    itemSelector: '.gallery-thumb',
                    gutter: '.gallery-gutter-sizer'
                });
            });
        },

        initPhotoswipe: function () {
            var self = this;

            $('.picture').each(function () {
                var $pic = $(this);
                self.photoswipeItems.push(self.getPhotoswipeAttributes($pic));
            });

            var hashData = self.photoswipeParseHash();
            if(hashData.pid && hashData.gid) {
                self.openPhotoswipe( hashData.pid - 1, false);
            }

        },

        openPhotoswipe: function(index,  showAnimation){
            var self = this,
                options = {
                    index: index,
                    bgOpacity: 1,
                    showHideOpacity: true,
                    closeOnScroll: false,
                    getPageURLForShare: function( ) {
                        // `pswp` is the PhotoSwipe instance object,
                        // you should define it by yourself
                        var hash = window.location.hash;
                        var url = window.location.href.replace(hash, '');
                            url += hash.replace('#&', '?');
                        return url;
                    }
                };
            if( ! showAnimation ){
                options.showAnimationDuration = 0;
            }

            var lightBox = new PhotoSwipe(self.settings.$pswp[0], PhotoSwipeUI_Default, self.photoswipeItems, options);
            lightBox.init();
            lightBox.listen('beforeChange', function() {
                self.pauseVideo();
            });
            lightBox.listen('close', function() {
                self.pauseVideo();
            });

        },

        pauseVideo: function(){
            console.log('pauseVideo');
            var iframe = $('.video-wrapper iframe').get(0);
            if(iframe !== undefined){
                iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}','*');
            }

        },

        getPhotoswipeAttributes: function ($pic) {

            var $bigLink = $pic.find('a');
            var $href = $bigLink.attr('href'),
                $size = $bigLink.data('size').split('x'),
                $html = $bigLink.data('html'),
                $width = $size[0],
                $height = $size[1];

            var attributes = {
                src: $href,
                w: $width,
                h: $height
            };

            if($html !== undefined){
                attributes.html = $html;
            }

            return attributes;
        },
        photoswipeParseHash: function() {
            var hash = window.location.hash.substring(1),
                params = {};
            if(hash.length < 5) {
                hash = window.location.href.substring(window.location.href.indexOf('?') + 1);
                if(hash.length < 5) {
                    return params;
                }
            }

            var vars = hash.split('&');
            for (var i = 0; i < vars.length; i++) {
                if(!vars[i]) {
                    continue;
                }
                var pair = vars[i].split('=');
                if(pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if(params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            return params;
        }
    });

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Gallery(this, options));
            }
        });
    };

})(jQuery, window, document, PhotoSwipe, PhotoSwipeUI_Default);
